<template>
  <div class="card" style="min-height: 200px">
    <vue3-html2pdf
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      pdf-content-width="100%"
      :htmlToPdfOptions="htmlToPdfOptions"
      ref="html2Pdf"
    >
      <template  v-slot:pdf-content>
        <div class="card-body">
          <div class="p-1">
            <div class="row">
              <div class="col-12 col-lg-6">
                <img :src="company.logo64" :alt="company.name" class="img-fluid" style="max-height: 100px;"/>
              </div>
              <div class="col-12 col-lg-6 mt-2" v-if="company.name">
                <p class="h4">{{ company.name }}</p>
                <p> Address: {{company.address}} </p>
                <p> Contact: {{company.phone}} </p>
              </div>
            </div>
          </div>
          <div class="col-12 px-2">
            <p class="h1 m-0 text-center">
              Journal Voucher
            </p>
            <hr>
          </div>
          <div class="row p-2" v-if="journal.total_amount">
            <div class="col-12 col-lg-6">

                <p>
                    <strong> Paid from </strong> <br>
                    <span>{{credit.head.name}}</span>
                </p>

                <p>
                    <strong> Journal Ref </strong> <br>
                    <span>{{journal.payment_ref}}</span>
                </p>

                <p>
                    <strong> Journal Date </strong> <br>
                    <span>{{journal.payment_ref_date}}</span>
                </p>

            </div>
            <div class="col-12 col-lg-6 text-right">
                <p><strong>Voucher No: </strong> {{journal_voucher_number}}</p>
                <p><strong>Voucher Date: </strong> {{journal.date}}</p>
            </div>
            <div class="col-12">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Accounts Description</th>
                            <th>Line Description</th>
                            <th>Cost Centre</th>
                            <th>Party Name</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in tableItems">
                            <td>
                                <p class="fw-bolder">{{ item.head.name }}</p>
                                <p v-if="item.activity !== null ">Budget: {{ item.activity?.name }}</p>
                                <p v-if="item.ngoab_head !== null ">NGOAB: {{ item.ngoab_head?.name }}</p>
                                <p v-if="item.project !== null ">Project: {{ item.project?.name }}</p>
                                <p v-if="item.donor !== null ">Donor: {{ item.donor?.name }}</p>
                            </td>
                            <td>{{ item.note }}</td>
                            <td>{{ item.cost_centre?.name }}</td>
                            <td>{{ item.party?.name }}</td>
                            <td class="text-right">{{ commaFormat(item.debit) }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="4" class="text-right">Total</td>
                            <td class="text-right">{{commaFormat(journal.total_amount)}}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="col-12 mt-2">
                <p><strong>Amount in words: </strong>{{currencyToWord(journal.total_amount)}}</p>
                <p class="fw-bolder">Voucher Memo: </p>
                <p>{{journal.note}}</p>
            </div>
            <div class="row d-flex justify-contend-between">
              <div class="col-12 col-lg-4 mt-2">
                <p class="mb-3">
                  <strong>Prepared By: </strong>
                </p>
                <p v-if="journal.user">
                  {{journal.user.name}} <br>
                </p>
              </div>
              <div class="col-12 col-lg-4 mt-2 text-center">
                <p class="mb-3">
                  <strong>Reviewed By: </strong>
                </p>

              </div>
              <div class="col-12 col-lg-4 mt-2 text-right">
                <p class="mb-3">
                  <strong>Approved By: </strong>
                </p>

              </div>
            </div>
          </div>
        </div>
      </template>
    </vue3-html2pdf>
    <div class="card-footer">
      <button class="btn btn-primary float-end" @click="exportToPDF">Download as PDF</button>
    </div>
    <Loader v-if="loader"/>
  </div>
</template>

<script setup>
    import handleCompany from "@/services/modules/company";
    import Loader from "@/components/atom/LoaderComponent";
    import {onMounted, ref, inject, computed} from "vue";
    import {useRoute} from "vue-router";
    import Vue3Html2pdf from "vue3-html2pdf";
    import figureFormatter from '@/services/utils/figureFormatter'
    import handleJournal from "@/services/modules/journal";

    const company = ref({});
    const loader = ref(false);
    const journal = ref({});
    const html2Pdf = ref(null);
    const credit = ref({});
    const {fetchCompanyInfo} = handleCompany();
    const {fetchJournal} = handleJournal();
    const {commaFormat, currencyToWord} = figureFormatter();
    const route = useRoute();
    const showError =  inject('showError');
    const journal_voucher_number = computed(() => {
        return journal.value.voucher_no;
    });
    const htmlToPdfOptions = ref({
        margin: 0,
        filename: journal_voucher_number,
        image: {
            type   : 'jpeg',
            quality: 1
        },
        enableLinks: false,
        html2canvas: {
            scale  : 2,
            useCORS: true
        },
        jsPDF: {
            unit       : 'mm',
            format     : 'a3',
            orientation: 'portrait'
        }
    });
    const tableItems = computed(() => {
        if(typeof journal.value.ledgers !== "undefined"){
            return journal.value.ledgers.filter((ledger) => {
                if(ledger.credit === null)
                    return ledger;
            })
        } else {
            return [];
        }
    })
    const exportToPDF = () => {
        html2Pdf.value.generatePdf();
    }
    onMounted(() => {
        loader.value = true;
        const companyQuery = `?company_id=${route.params.companyId}`;
        const fetchCompany = fetchCompanyInfo(route.params.companyId);
        const fetchJournalVoucher = fetchJournal(route.params.journalId, companyQuery);
        Promise.all([
            fetchCompany.then(res => {
                company.value = res.data;
            }),
            fetchJournalVoucher.then(res => {
                if(res.data){
                    journal.value = res.data;
                    let ledger = res.data.ledgers.find(i => i.debit === null)
                    if(ledger) credit.value = ledger
                }
            })
        ])
            .then( () => loader.value = false )
            .catch( (err) => {
                showError("Something went wrong");
                loader.value = false;
            } )
    })
</script>
